import type { Provider } from '@supabase/supabase-js';
import getEnv from '~/core/get-env';

const env = getEnv() ?? {};
const production = env.NODE_ENV === 'production';
const development = env.NODE_ENV === 'development';

enum Themes {
  Light = 'light',
  Dark = 'dark',
}

const configuration = {
  release: env.APP_VERSION,
  site: {
    name: 'Dealday',
    description: 'Buyer Engagement Platform',
    themeColor: '#ffffff',
    themeColorDark: '#0a0a0a',
    siteUrl: env.SITE_URL,
    siteName: 'Dealday',
    twitterHandle: '',
    githubHandle: '',
    language: 'en',
    convertKitFormId: '',
    locale: env.DEFAULT_LOCALE,
  },
  auth: {
    // ensure this is the same as your Supabase project
    requireEmailConfirmation: false,
    // NB: Enable the providers below in the Supabase Console
    // in your production project
    providers: {
      emailPassword: development, // only true for dev
      phoneNumber: false,
      emailLink: !development, // makes no sense in development. super inconvenient
      oAuth: ['google', 'azure'] as Provider[],
    },
  },
  production,
  environment: env.ENVIRONMENT,
  theme: Themes.Light,
  features: {
    enableThemeSwitcher: true,
    enableAccountDeletion: getBoolean(env.ENABLE_ACCOUNT_DELETION, true),
    enableOrganizationDeletion: getBoolean(
      env.ENABLE_ORGANIZATION_DELETION,
      true,
    ),
  },
  paths: {
    signIn: '/auth/sign-in',
    signUp: '/auth/sign-up',
    signInFromLink: '/auth/link',
    callback: '/auth/callback',
    onboarding: `/onboarding`,
    appHome: '/rooms/overview',
    appProducts: {
      overview: '/products/overview',
      createIndex: '/products/create',
      createFirstStep: '/products/create/basic-info',
    },
    appProjects: {
      overview: '/rooms/overview'
    },
    appSnippets: {
      overview: '/snippets/overview'
    },
    settings: {
      profile: '/settings/profile',
      email: '/settings/profile/email',
      password: '/settings/profile/password',
    },
    api: {
      checkout: `/resources/ls/checkout`,
      customerPortal: `/resources/ls/customer-portal`,
      organizations: {
        create: `/resources/organizations/create`,
        transferOwnership: `/resources/organizations/transfer-ownership`,
        members: `/resources/organizations/members`,
      },
    },
  },
  sentry: {
    dsn: env.SENTRY_DSN,
  },
  subscriptions: {
    products: [
      /*{
        name: 'Pay As You Go',
        productId: 57713,
        description: '',
        badge: ``,
        features: [
          '10€ / Product',
          '10€ / Room',
          '5€ / User',
          'Unlimited Stakeholders',
          'Single-Sign-On'
        ],
        plans: [
          {
            name: '',
            price: '',
            variantId: 0,
          }
        ],
      },*/
      {
        name: 'Pro',
        badge: `Most Popular`,
        recommended: true,
        productId: production ? 377923 : 129102,
        description: 'dealday Pro',
        cancelAtPeriodEnd: false,
        features: [
          'Unlimited Products & Rooms',
          'Unlimited Stakeholders',
          'Single-Sign-On'
        ],
        plans: [
          {
            priceInt: 50,
            name: 'Month',
            price: '50€/user',
            variantId: production ? 568610 : 150485,
            trialPeriodDays: 0,
          },
          {
            priceInt: 500,
            name: 'Year',
            price: '500€/user',
            variantId: production ? 568620 : 508310,
            trialPeriodDays: 0,
          },
        ],
      },
      /*{
        name: 'Enterprise',
        description: '',
        badge: ``,
        features: [
          'Unlimited Products & Rooms',
          'Unlimited Users',
          'Unlimited Stakeholders',
          'CRM Integration',
          'Single-Sign-On'
        ],
        plans: [
          {
            name: '',
            price: '',
            variantId: 0,
            label: `Contact us`,
            href: `/contact`,
          },
        ],
      },*/
    ],
  },
};

export default configuration;

function getBoolean(value: unknown, defaultValue: boolean) {
  if (typeof value === 'string') {
    return value === 'true';
  }

  return defaultValue;
}
